/**************************/
/* BELOW 1920×1080 */
/**************************/
@media (max-width: 160em) {
}

/**************************/
/* BELOW 1536×864 */
/**************************/
@media (max-width: 96em) {
}

/**************************/
/* BELOW 1366×768 */
/**************************/
@media (max-width: 85.375em) {
}

/**************************/
/* BELOW 1280×720*/
/**************************/
@media (max-width: 80em) {
  .title-container--home {
    /* min-height: calc(80vh - 7rem); */
    padding: 5% 5%;
  }

  .box--container {
    width: 90%;
  }

  .box--container-approach {
    display: grid;
    grid-template-columns: 1fr;
  }

  .action--box:link,
  .action--box:visited,
  .action--box:hover,
  .action--box:active {
    justify-self: center;
    width: 30%;
    padding: 3.6rem;
  }

  .ph-globe-hemisphere-east,
  .ph-music-notes {
    font-size: 2.1rem;
  }

  .quote {
    height: 10rem;
  }

  .title-container--home h1 {
    font-size: 4.8rem;
  }
}

/**************************/
/* BELOW 763×1024*/
/**************************/
@media (max-width: 68em) {
  .submit-btn {
    width: 100%;
  }

  .ph-globe-hemisphere-east,
  .ph-music-notes {
    display: none;
  }

  .title-container--home {
    width: 75%;
    height: fit-content;
  }
  .title-container--home h1 {
    padding: 2.4rem 0;
  }
}

/**************************/
/* BELOW 412×915*/
/**************************/
@media (max-width: 37.5em) {
  .title-container--home {
    /* margin-top: 5rem; */
    /* min-height: calc(90vh); */
    /* padding-bottom: 3.6rem; */
    gap: 2.1rem;
    width: 85%;
    /* padding: 5%; */
  }

  .title-container--home h1 {
    font-size: 2.4rem;
    padding: 0.2rem 0;
  }

  .dedication {
    font-size: 1.8rem;
  }

  .explained--text {
    width: 95%;
  }

  .box--container {
    width: 85%;
  }

  .box--container-approach {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 7rem;
  }

  .action--box:link,
  .action--box:visited,
  .action--box:hover,
  .action--box:active {
    justify-self: center;
    width: 70%;
    padding: 3.6rem;
  }

  .home--text-box {
    width: 100%;
  }

  .pThree,
  .pFive {
    width: 100%;
  }

  .quote {
    font-size: 1.6rem;
  }
}

/**************************/
/* BELOW 360×800*/
/**************************/
@media (max-width: 22.5em) {
  .title-container--home {
    width: 90%;
  }
}

/***********************************/
/* BELOW 390 width and 700 height*/
/***********************************/
@media (max-width: 24.375em), (max-height: 43.75em) {
}
